<template>
  <v-container>
    <h2 style="text-align: center">
      <textloader-component textKey="Samarbetspartners" />
    </h2>
    <!-- Different images depending on color theme -->
    <v-list v-if="isDark">
      <v-list-item
        v-for="sponsor in sponsorList"
        :key="sponsor.imageDark"
        v-on:click.stop="clickSponsor(sponsor.link)"
      >
        <v-img max-height="120" contain :src="sponsor.imageDark"></v-img>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item
        v-for="sponsor in sponsorList"
        :key="sponsor.image"
        v-on:click.stop="clickSponsor(sponsor.link)"
      >
        <v-img max-height="120" contain :src="sponsor.image"></v-img>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'

export default {
  data: () => {
    return {
      sponsorList: [
        {
          link: 'https://animech.com/en/',
          image: require('@/assets/animec-light.png'),
          imageDark: require('@/assets/animec-dark.png')
        },
        {
          link: 'https://swace.se/',
          image: require('@/assets/swace-light.png'),
          imageDark: require('@/assets/swace-dark.png')
        },
        {
          link: 'https://www.arriver.com/',
          image: require('@/assets/arriver-light.jpg'),
          imageDark: require('@/assets/arriver-dark.jpg')
        },
        {
          link: 'https://unibap.com/',
          image: require('@/assets/unibap_trans.png'),
          imageDark: require('@/assets/unibap_trans.png')
        }
      ]
    }
  },
  components: {
    TextloaderComponent
  },
  methods: {
    clickSponsor: function name (link) {
      window.location.href = link
    }
  },
  computed: {
    isDark: function () { return this.$vuetify.theme.dark }
  }
}
</script>

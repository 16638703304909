import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '@/plugins/vuetify'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    lang: 'swe',
    consent: false,
    latestSchedule: undefined,
    landingPage: undefined
  },
  getters: {
    lang: state => state.lang,
    consent: state => state.consent,
    latestSchedule: state => state.latestSchedule,
    landingPage: state => state.landingPage
  },
  mutations: {
    initialiseStore: (state) => {
      const consent = localStorage.getItem('consent') === 'true'
      state.consent = consent

      const lang = localStorage.getItem('lang')
      if (state.consent && lang != null) {
        state.lang = lang
      } else {
        state.lang = 'swe'
      }
      const dark = localStorage.getItem('dark') === 'true'
      if (state.consent) {
        Vuetify.framework.theme.dark = dark
      } // else leave theme.dark as it's default value if by any chance Vuetify can get dark mode from the browser itself.

      const latestSchedule = localStorage.getItem('latestSchedule')
      if (state.consent && latestSchedule != null) {
        state.latestSchedule = latestSchedule
      } // else leave it as undefined (see store.state above)

      const landingPage = localStorage.getItem('landningPage')
      if (state.consent && landingPage != null) {
        state.landingPage = landingPage
      } // else leave it as undefined (see store.state above)
    },
    changeConsent: (state, newConsent) => {
      state.consent = newConsent
      if (!newConsent) {
        localStorage.clear() // CLEARS EVERYTHING IN LOCALSTORAGE!!!!!
      } else {
        localStorage.setItem('consent', newConsent)
      }
    },
    changeLang: (state, newLang) => {
      state.lang = newLang
      if (state.consent) {
        localStorage.setItem('lang', newLang)
      }
    },
    changeDark: (state, newDark) => {
      Vuetify.framework.theme.dark = newDark
      if (state.consent) {
        localStorage.setItem('dark', newDark.toString())
      }
    },
    changeLatestSchedule: (state, newSchedule) => {
      state.latestSchedule = newSchedule
      if (state.consent) {
        localStorage.setItem('latestSchedule', newSchedule)
      }
    },
    changeLandingPage: (state, newLandingPage) => {
      state.landingPage = newLandingPage
      if (state.consent) {
        localStorage.setItem('landningPage', newLandingPage)
      }
    }
  }
})

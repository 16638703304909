import Vue from 'vue'
import VueRouter from 'vue-router'
import navdata from '@/assets/navdata.json'

Vue.use(VueRouter)

// Get page info
const routes = []

// Import and add all "standard" pages
for (const page of navdata.pageinfo) {
  routes.push({
    path: page.path,
    component: () => import('@/views/' + page.componentPath)
  })
}

// Add misc. pages
routes.push(
  {
    path: '/schema/:classID',
    props: true,
    component: () => import('@/views/aktuellt/Schema')
  },
  {
    path: '/allahandelser/:eventID',
    props: true,
    component: () => import('@/views/aktuellt/AllaHandelser')
  },
  {
    path: '/addEvent',
    props: true,
    component: () => import('@/views/AddEvent')
  },
  {
    path: '/landingpage',
    component: () => import('@/views/LandingPage')
  },
  {
    path: '/settings',
    component: () => import('@/views/Settings')
  },
  {
    path: '/furka',
    component: () => import('@/games/Furka')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404')
  }
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div style="width: 100%; height: 100%;">
    <div>
      <h1 style="text-align: center">Farv: {{counter}} Fits: {{hit_counter}}</h1>
      <h1 v-if="game_on == false" style="text-align: center; font-size: 100px"> Game Over! </h1>
    </div>
    <div id="FurkaContainer">
      <img @click="FurkaClick" class="Furka" id="Furka" src="@/assets/icon-192x192.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Furka',
  data: function () {
    return {
      deg: 0,
      w: 3,
      t: 0,
      second_counter: 0,
      w_translate: 50,
      r_x: window.innerWidth / 3,
      r_y: window.innerHeight / 3,
      x_translate: 0,
      y_translate: 0,
      counter: 0,
      hit_counter: 0,
      click_counter: 0,
      game_on: true
    }
  },
  methods: {
    FurkaClick () {
      if (this.game_on && this.click_counter < 15) {
        // Increase angular velocity every click
        this.w = this.w * 1.05
        this.w_translate = this.w_translate * 1.0005

        // Increase hit count
        this.hit_counter = this.hit_counter + 1

        // console.log('Increase!')
        this.click_counter = this.click_counter + 1
      }
    },
    gameLoop () {
      // Uptade time
      this.t = this.t + 1 / 30

      // Anti clickbot
      this.second_counter = this.second_counter + 1
      if (this.second_counter % 30) {
        this.second_counter = 0
        this.click_counter = 0
      }

      // Update angle
      this.deg = this.deg + this.w

      // Update radius
      this.x_translate = this.w_translate * this.t * Math.PI / 180
      this.y_translate = this.w_translate * this.t * Math.PI / 180

      // Transform (translate and rotate)
      document.getElementById('Furka').style.transform = `translate(${this.r_x * Math.cos(this.x_translate)}px, ${this.r_y * Math.sin(this.y_translate)}px) rotateY(${this.deg}deg) rotateX(${this.deg}deg) rotate(${this.deg}deg)`

      // Counts number of rotations (X, Y, Z)
      this.counter = Math.floor(this.deg / 360)

      // Slow down & no -vel.
      this.w = this.w * 0.9983
      // this.w_translate = this.w_translate * 0.9998
      if (this.w < 1) {
        // Game Over
        this.gameover()
      }
    },
    gameover () {
      // Initialize pic
      document.getElementById('Furka').style.transform = `translate(0px, ${-this.r_y}px)`
      this.w = 0
      this.w_translate = 0
      // console.log('Game Over')
      this.game_on = false
    }
  },
  mounted: function () {
    setInterval(this.gameLoop, 1000 / 30)

    // Set radius according to windowsize
    this.r_y = document.getElementById('FurkaContainer').clientHeight / 4
    this.r_x = document.getElementById('FurkaContainer').clientWidth / 4
  }
}
</script>

<style>
#Furka {
  width: 20%;
}
#FurkaContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div v-html="loadText(lang, textKey)">
  </div>
</template>

<script>
import textManager from '@/Texthanterare.js'
export default {
  props: {
    textKey: String
  },
  computed: {
    lang: function () {
      return this.$store.getters.lang
    }
  },
  methods: {
    loadText: function (lang, key) {
      return textManager(lang, key)
    }
  }
}
</script>

const data = require('./assets/text.json')

export default function textManager (lang, key) {
  if (key in data) {
    if (lang in data[key]) {
      return data[key][lang]
    } else {
      console.error('Language ' + lang + ' was not found for textkey ' + key)
      return 'Something went wrong loading the text :('
    }
  } else {
    console.error('Textkey ' + key + ' was not found!')
    return 'Something went wrong loading the text :('
  }
}
